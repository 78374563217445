<template>
    <modal ref="modalIngresarDinero" titulo="Ingresar dinero a la caja fuerte" no-aceptar adicional="Ingresar" @adicional="crearIngresoTesoreria">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-10">
                    <ValidationProvider v-slot="{errors}" :rules="`required|ingreso:${valor_pendiente}`" name="ingreso" class="d-flex flex-column">
                        <div class="d-middle border br-6 w-100" style="height:38px;">
                            <input-miles-moneda v-model="model.valor" :id-moneda="moneda" placeholder="Digite un abono" border maxlength="13" />
                            <div class="py-1 d-middle-center px-1 mr-1 h-100">
                                {{ sigla }}
                            </div>
                        </div>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-10 mt-3">
                    <ValidationProvider v-slot="{errors}" rules="required" name="comentario">
                        <p class="text-general pl-3 f-14">Comentario</p>
                        <el-input v-model="model.comentario" type="textarea" :rows="5" placeholder="Deja un comentario" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Cajas from "~/services/flujoDinero/cajas";
import { extend } from 'vee-validate';
extend('ingreso', {
    validate(value, { valor_pendiente }){
        return +(value) <= +(valor_pendiente)
    },
    params: ['valor_pendiente'],
    message: 'El {_field_} supera el valor del restante ({valor_pendiente}).'
});
export default {
    data(){
        return {
            model: {
                id_caja: null,
                valor: null,
                id_moneda: null,
                comentario: '',
                fecha: '',
            },
            sigla: 'COP',
            valor_pendiente: null,
            moneda: null,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
    },
    methods: {
        toggle({ fecha, id_caja, valor_pendiente, sigla, moneda}){
            this.model.fecha = fecha
            this.model.id_caja = id_caja
            this.model.id_moneda = moneda
            this.valor_pendiente = valor_pendiente
            this.sigla = sigla
            this.moneda = moneda
            this.$refs.modalIngresarDinero.toggle();
        },
        async crearIngresoTesoreria(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.idm_moneda = this.id_moneda
                const {data} = await Cajas.crearIngresoTesoreria(this.model)
                this.notificacion('', data.message, 'success')
                this.$emit('ingreso', { pago: data.pago, saldo: data.saldo})
                this.limpiar()
                this.$refs.modalIngresarDinero.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                id_caja: null,
                valor: null,
                id_moneda: null,
                comentario: '',
                fecha: '',
            }
        }
    }
}
</script>
